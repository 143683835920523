import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import './cancel.css';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import axios from 'axios';
import { any } from 'prop-types';
import { Route } from '../models/site-links';

const CancelOptions = ({ location }) => {
  let sessionID;
  let id;
  let giveOffers;
  if (location.state) {
    sessionID = location.state.sessionID;
    id = location.state.id;
    giveOffers = location.state.giveOffers;
  } else if (typeof window !== 'undefined') {
    // navigate to contact support
    navigate(Route.Cancel);
  }

  const [cancelOrStay, setCancelOrStay] = useState('stay');
  const [offerCode, setOffercode] = useState<number>(4);

  const formData = {
    accountId: id,
    sessionId: sessionID,
    offerCode: offerCode,
    offerDetails: ' ',
  };

  useEffect(() => {
    axios.post('https://core.prod.patronscan.servallapps.com/api/v1/cancel/take-offer/', formData);
  }, [offerCode]);

  const submitForm = async (offCode) => {
    setOffercode(offCode);

    axios.post('https://core.prod.patronscan.servallapps.com/api/v1/cancel/take-offer/', formData);
  };

  // Need to handle possible errors

  return (
    <div>
      <div className="flex justify-center">
        <div className="mb-20 mt-32">
          <p className="header-s">Cancel Subscription</p>
          <p className="subheader-s">Before you decide to cancel, consider these offers:</p>
          <div className="cards-s">
            <div className="card-s">
              <p className="card-title-s">50% off!</p>
              <p className="card-subtitle-s">
                Enjoy a 50% reduction on your monthly subscription fee for a period of three months.
                <br></br>
                <br></br>
                We’re here to help.
              </p>
              <button
                style={{ marginTop: '100px', marginLeft: '-15px' }}
                onClick={(e) => {
                  setOffercode(1);
                  setCancelOrStay('stay');
                }}
              >
                <AniLink
                  fade
                  className="block hover:text-grey-dark transition-color lg:text-base industry-picks"
                  to="/cancel-success"
                  state={{ id, cancelOrStay }}
                >
                  <Button
                    buttonColor={ButtonColor.Primary}
                    buttonStyle={ButtonStyle.RetentionButtonMed}
                    text={'Get Discount'}
                    buttonId={'DiscountButton'}
                  />
                </AniLink>
              </button>
            </div>
            <div className="card-s">
              <p className="card-title-s">Free Exchange</p>
              <p className="card-subtitle-s">
                Trade in your hardware for another model that better suits your operational needs at
                no charge.
                <br></br>
                <br></br>
                Try something new!
              </p>
              <button
                style={{ marginTop: '100px', marginLeft: '-15px' }}
                onClick={(e) => setOffercode(2)}
              >
                <AniLink
                  fade
                  className="block hover:text-grey-dark transition-color lg:text-base industry-picks"
                  to="/cancel-exchange"
                  state={{ id, cancelOrStay, sessionID, offerCode, giveOffers }}
                >
                  <Button
                    buttonColor={ButtonColor.Primary}
                    buttonStyle={ButtonStyle.RetentionButtonMed}
                    text={'Hardware options'}
                    buttonId={'ExchangeButton'}
                  />
                </AniLink>
              </button>
            </div>
            <div className="card-s">
              <p className="card-title-s">1 Free Month</p>
              <p className="card-subtitle-s">
                Receive one free month of our highest tier subscription.
                <br></br>
                <br></br>
                <a style={{ fontSize: '18px', fontWeight: '600' }}>Complete Plan</a>
              </p>
              <ul className="card-description-s">
                <li style={{ listStyleType: 'disc' }}>Flagged patrons network</li>
                <li style={{ listStyleType: 'disc' }}>SMS notifications</li>
                <li style={{ listStyleType: 'disc' }}>Demographic reports</li>
              </ul>
              <button
                style={{ marginTop: '14px', marginLeft: '-15px' }}
                onClick={(e) => {
                  setOffercode(3);
                  setCancelOrStay('stay');
                }}
              >
                <AniLink
                  fade
                  className="block hover:text-grey-dark transition-color lg:text-base industry-picks"
                  to="/cancel-success"
                  state={{ id, cancelOrStay }}
                >
                  <Button
                    buttonColor={ButtonColor.Primary}
                    buttonStyle={ButtonStyle.RetentionButtonMed}
                    text={'Get 1 free month'}
                    buttonId={'FreeMonthButton'}
                  />
                </AniLink>
              </button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div className="bottomButtons-s">
              <div className="backButton">
                <AniLink
                  fade
                  className="block industry-picks"
                  to={'/cancel?id=' + id}
                  state={{ id, sessionID }}
                >
                  <Button
                    buttonColor={ButtonColor.BlackCancelPrimary}
                    buttonStyle={ButtonStyle.RetentionButtonMed}
                    text={'Back'}
                    buttonId={'BackButton'}
                  />
                </AniLink>
              </div>
              <button onClick={(e) => submitForm(4)}>
                <AniLink
                  fade
                  className="block industry-picks"
                  to="/cancel-why"
                  state={{ id, sessionID, giveOffers }}
                >
                  <Button
                    buttonColor={ButtonColor.CancelSubscription}
                    buttonStyle={ButtonStyle.RetentionButtonMed}
                    text={'Cancel subscription'}
                    buttonId={'CancelSubscriptionButton'}
                  />
                </AniLink>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelOptions;
